import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Logo from '../images/lechner-tech.svg'
import BesonderWebsite from '../images/besonder-website.png'

import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="logo">
      <img src={Logo} alt="lechner-tech-logo" />
    </div>
    <div className="sub-logo-text">
      Custom Software Development
    </div>
    <h1>Projects</h1>
    <div className="portfolio">
      <div className="card">
        <a href="http://besonder.at" target="_blank" rel="noreferrer">
          <div className="card-image">
            <img src={BesonderWebsite} alt="Besonder Website" />
            <div className="card-info">
              www.besonder.at
            </div>
          </div>
        </a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
